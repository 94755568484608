@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Comic Neue', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar{
  background-color: #22F291 !important;
}

.navbar-brand{
  color: #555555 !important;
  font-weight: bold;
  font-size: 2rem;
}

.nav-link{
  color: #555555;
  font-weight: bold;
  font-size: 1.25rem;
}

.nav-link:hover{
  background-color: white;
  color: #555555;
  font-weight: bold;
}

.navbar-toggler {
  color: #555555;
  background-color: white;
}

.card-img-top{
  height:200px;
  width:100% !important;
  border-radius: 5px !important;
}

.btn{
  background-color: #22F291 !important;
  color: #555555 !important;
  box-shadow: none !important;
}

.btnLogin{
  background-color: #ffffff !important;
  color: #555555 !important;
  box-shadow: none !important;
}

.box {
  box-shadow: rgba(34, 242, 145, 1) 0px 0px 10px 3px;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
}

.rotaimg{
  height: 300px !important;
  border-radius: 10px !important;
  max-width: 100%;
}

.dropdown-menu{
  background-color: #22F291 !important;
 
}
input , select , .ant-picker{
  box-shadow: none !important;
  margin: 5px !important;
  border: 1pt solid #22F291 !important;
  width: 100% !important;
  border-radius: 3px !important;
  height: 40px !important;
}

.dropdown-item{
  background-color: #22F291 !important;
  color: #555555 !important;
}

.ant-picker-input input{
  border: none !important;
}


.ant-table-thead .ant-table-cell {
  background-color: #22F291 !important;
  color: #555555 !important;
  font-weight: bold !important;
}


.backgroundHome{
  height: 500px;
  background-color: white;
  background-image: url('https://res.cloudinary.com/dqu1cfm2e/image/upload/v1710084355/evCarHire/ev-banner2_oqjd9y.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Mappage.css */
.mappage-container {
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 99%;
  margin: 10px;
  padding: 10px;
}

.chargepoints-list {
  display: flex;
  gap: 20px;
  text-align: left;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}

.map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}

.map-title {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-weight: bolder;
}

.rental-locations{
  text-align: center; 
  padding: 20px;
  width: 80%; 
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing{
  background-color: white;
}

.landing-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Homepage link to join*/
.content-box {
  background-color: white;
  padding: 20px;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  position: absolute;
  top: 50px;
  left: 50px;
  display: flex;
  justify-content: left;
  align-items: left;
}

.btnHome{
  background-color: #22F291;
  color: #555555;
  box-shadow: none;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: 'Comic Neue', cursive !important;
  text-decoration: none !important;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btnHome:hover{
  background-color: #ffff;
  color: #22F291;
  font-family: 'Comic Neue', cursive !important;
  text-decoration: none !important;
  font-weight: bold;
}

.btnHome h2, i {
  font-size: 48px;
}


/* Icons */
#custom-icon {
  font-size: 96px;
  color: #22F291;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 20px;
}

#evhome-icon {
  font-size: 48px; 
}

/* Admin pages */

.admin-carpage-container {
  padding: 20px;
}

.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
}

@media (max-width: 768px) {
  .responsive-table .ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.emailLink , .contactLink{
  color: #555555;
  font-size: 1.5rem;
}